// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-carporty-js": () => import("./../../../src/pages/products/carporty.js" /* webpackChunkName: "component---src-pages-products-carporty-js" */),
  "component---src-pages-products-dachyrzymskie-js": () => import("./../../../src/pages/products/dachyrzymskie.js" /* webpackChunkName: "component---src-pages-products-dachyrzymskie-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-lamele-js": () => import("./../../../src/pages/products/lamele.js" /* webpackChunkName: "component---src-pages-products-lamele-js" */),
  "component---src-pages-products-ogrodyzimowe-js": () => import("./../../../src/pages/products/ogrodyzimowe.js" /* webpackChunkName: "component---src-pages-products-ogrodyzimowe-js" */),
  "component---src-pages-products-poliweglan-js": () => import("./../../../src/pages/products/poliweglan.js" /* webpackChunkName: "component---src-pages-products-poliweglan-js" */),
  "component---src-pages-products-zabudowa-js": () => import("./../../../src/pages/products/zabudowa.js" /* webpackChunkName: "component---src-pages-products-zabudowa-js" */)
}

